<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                title="Create IPD Bill"
                @onClickCloseButton="goToList"
            />
        </div>

        <div class="my-2 px-2">
            <TableHeaderIPDBillAdd
                :formData="formData"
                :patient="patient"
                :tableItems="tableItems"
            />
        </div>

        <div class="col-12 px-2">
            <ListTable 
                :tableItems="tableItems"
                @addNewItem="addNewItem"
                :vatRate="vatRate"
                :salesAccountHeads="salesAccountHeads"
            />
        </div>
        <div class="row px-2">
            <div class="col-md-6">
                <div class="mb-1">
                    <label class="form-label" for="description">Ledger Memo</label>
                    <vField
                        as="textarea"
                        name="description"
                        type="number"
                        class="form-control"
                        v-model="formData.description"
                    />
                </div>
            </div>
            <div class="col-md-5 ml-auto mt-auto mb-1">
                <div class="d-flex flex-wrap gap-1 gy-2">
                    <button :disabled="loading" @click="handleSubmit(false)" class="btn btn-primary">Save</button>
                    <button :disabled="loading" @click="handleSubmit(true)" class="btn btn-primary">Save & Print</button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script setup>
import {inject, onMounted, provide, reactive, ref} from 'vue';
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/billing/ipd-bill/InvoiceHospitalTable.vue';
import {useRoute, useRouter} from 'vue-router';
import {generateUUID} from '@/services/utils/global';
import handlePurchase from '@/services/modules/purchase';
import {vatRate} from '@/data/inventory.js';
import handleCompany from "@/services/modules/company";
import hospitalPdfPrinterHelper from '@/services/utils/hospitalPdfPrinterHelper';
import TableHeaderIPDBillAdd from "@/components/molecule/company/hospital/billing/ipd-bill/TableHeaderHospitalBillAdd.vue";
import handleHospital from "@/services/modules/hospital";
import useDate from "@/services/utils/day";

const { generatePdf } = hospitalPdfPrinterHelper();
    const { fetchProductList, fetchSalesBill, ...rest } = handlePurchase();
    const { fetchCompanyInfo } = handleCompany();
    const {fetchSingleNote} = handleHospital();

    const $route = useRoute();
    const $router = useRouter();

    const companyId = $route.params.companyId;
    const loader = ref(true);
    const loading = ref(false);
    const salesAccountHeads = ref([]);
    const totals = ref({});
    const invoiceRes = ref({});
    const company = ref({});
    const patient = ref({});
    const dateTime = useDate();

    let formData = ref({
        company_id: companyId,
        contact_profile_id: null,
        account_head_id: null,
        ipd_register_id: null,
        vat_payable_account_head_id: null,
        mop_account_head_id: null,
        receipt_reference: null,
        receipt_description: "IPD bill payment",
        payment: null,
        project_id: null,
        cost_centre_id: null,
        location_id: null,
        discount_head_id: null,
        adjustment_amount: 0,
        status: 'active',
        bill_number: '',
        date: '',
        has_item_detail: true,
        description: '',
        order_number: null,
        sales_person_id: null,
        commission_percent: null,
        lc_number: null,
        item_details: []
    });

    provide('formData', formData);
    provide('totals', totals);
    const showSuccess =  inject('showSuccess');
    const showError =  inject('showError');

    onMounted( async() => {
        const companyQuery = `?company_id=${companyId}`;
        const receiveAbleRes = rest.fetchAccountReceivable(companyQuery);
        const payableRes = rest.fetchAccountPayable(companyQuery);
        const vatPayableRes = rest.fetchVatPayable(companyQuery);
        const fetchCompany = fetchCompanyInfo(companyId)
        const salesAccountHeadsRes = rest.getAccountHeadBySlag("sales_accounts", companyQuery)

        Promise.all([
            payableRes.then(res=> {
                if(res.data) formData.value.account_payable_head_id = res.data[0].id;
            }),
            receiveAbleRes.then(res=> {
                if(res.data) formData.value.account_head_id = res.data[0].id;
            }),
            vatPayableRes.then(res=> {
                if(res.data) formData.value.vat_payable_account_head_id = res.data[0].id;
            }),
            fetchCompany.then(res=> {
                if(res.data) company.value = res.data;
            }),
            salesAccountHeadsRes.then(res => {
                if(res.data) salesAccountHeads.value = res.data
            }),
            fetchSingleNote(companyQuery, $route.params.ipdRegisterId).then(res => {
                if (res.data) {
                    patient.value = res.data;
                    formData.value.contact_profile_id = res.data.patient.id
                    formData.value.ipd_register_id = res.data.id
                    formData.value.service_resource_id = res.data.human_resource_id
                }
            })
        ])
        .then(() => {loader.value=false})
        .catch(()=>{loader.value=false})
    })

    let tableItems = reactive([]);

    const addNewItem = () => {

        tableItems.push({
            id: generateUUID(),
            product_id: null,
            name: '',
            description: '',
            quantity : 1,
            rate: 0,
            discount_percent: 0,
            vat: 15,
            sub_total: 0,
            discount_amount: 0,
            vat_amount: 0,
            total: 0,
            isEdit: false,
        });

    }

    const goToList = () => {
        $router.push({name: 'ipd-bill-list',
            params: $route.params,
            query: $route.query

        })
    }

    const handleSubmit = async (print=false) => {
        if(!formData.value.date) formData.value.date = dateTime.currentDate();
        formData.value.item_details = tableItems;
        let copyFormData = JSON.parse(JSON.stringify(formData.value))
        copyFormData.item_details = formData.value.item_details
        copyFormData.sale_type = "ipd_invoice"
        copyFormData.receipt_type = "ipd_invoice"

        loading.value = true;

        await rest.storeBillPayment(copyFormData)
        .then(res => {  
            if(res.status) {
                showSuccess(res.message)
                if(print) goToPrint(res.data.id)
                goToList();
                return;
            }
            showError(res.message)
        })
        .catch(err=> {
            showError(err.response.data.message)
        })
        .finally(res => {
            loading.value = false;
        })
    }

    const goToPrint = (id) => {
        const companyQuery = `?company_id=${companyId}`;

        fetchSalesBill(id, companyQuery).then( (res) =>{
            if( res ){
                invoiceRes.value = res.data;
            }
        }).then( () => {
            generatePdf(company.value, invoiceRes.value, 'BILL', true, true)
        }).catch( (err) => {
            console.log(err);
        })
    }

</script>
<style scoped>
address{
    margin-bottom: 0 !important;
}
</style>