<template>
    <tr v-show="!item.isEdit" @click="openEditLine(index)">
        <td class="pl-10">
            <div class="col">
                <strong>
                  {{ item.name }}
                </strong>
                {{ item.description }}
            </div>
        </td>
        <td class="text-center">{{ item.service_resource_name }}</td>
        <td class="text-center">{{ item.service_resource_desc_name }}</td>
        <td class="text-center">{{ item.service_resource_price }}</td>
        <td class="text-center">{{ item.quantity }}</td>
        <td class="text-center">{{ item.rate }}</td>
        <td class="text-center">{{ item.amount }}</td>
        <td class="text-center">{{ item.discount_percent }}%</td>
        <td class="text-center">{{ item.discount_amount }}</td>
        <td class="text-center">{{ amountAfterDiscount }}</td>
        <td class="text-center">{{ item.vat }}%</td>
        <td class="text-center">{{ item.vat_amount }}</td>
        <td class="text-center">{{ totalAmount }}</td>
        <td>
            <div>
                <button @click="deleteItem(index)" class="btn btn-sm btn-dlt">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>
    
    <tr v-show="item.isEdit" :class="item.isEdit && 'tr-box-shadow tr-edit'">
        <td style="min-width: 250px">
            <div class="col">
                <strong>
                    {{ item.name }}
                </strong>
                {{ item.description }}
            </div>
        </td>
        <td style="min-width: 250px">
          <AsyncSelect
            placeholder="Select service resource"
            v-model="item.service_resource"
            :api-service="fetchContactProfiles"
            :additional-query="additionalQuery"
          />
        </td>
        <td style="min-width: 250px">
          <v-select
            placeholder="Select service type"
            v-model="item.resource_description_id"
            :options="serviceResourceDescriptions"
            label="name"
            :reduce="name => name.id"
          />
        </td>
        <td style="min-width: 100px">
          <div class="td-inline">
            <input
                v-model.trim="item.service_resource_price"
                type="number" class="form-control"
                placeholder="Service price" :min="0"
            >
          </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.quantity"
                    type="number" class="form-control"
                    placeholder="Qty" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.rate"
                    type="number" class="form-control"
                    placeholder="Rate" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    readonly
                    v-model="amount"
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.discount_percent"
                    type="number" class="form-control"
                    placeholder="Discount(%)" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="item.discount_amount"
                    name="discount-amount"
                    type="number"
                    class="form-control text-right"
                    min="0"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="amountAfterDiscount"
                    name="amount"
                    type="number"
                    class="form-control text-right"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="item.vat"
                    id="vat"
                    as="select"
                    name="vat"
                    class="form-select"
                >
                    <option
                        v-for="(vat, i) in vatRate"
                        :value="vat.value"
                        :key="i"
                    >
                        {{vat.label}}
                    </option>
                </vField>
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="item.vat_amount"
                    name="vat_amount"
                    type="number"
                    class="form-control text-right"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="totalAmount"
                    readonly
                    name="total_amount"
                    type="number"
                    class="form-control text-right"
                />
            </div>
        </td>
        <td>
            <div>
                <button @click="deleteItem(index)" class="btn btn-sm btn-dlt">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>

</template>
<script setup>
import {computed, inject, ref, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchase from "@/services/modules/purchase";
import handleInventory from "@/services/modules/inventory";
import {useRoute} from "vue-router";
import handleContact from "@/services/modules/contact";
import handleHospital from "@/services/modules/hospital";

    const props = defineProps(['item', 'index', 'onOpenEditItem', 'onDeleteItem', 'vatRate'])
    const emit = defineEmits(['onOpenEditItem', 'onDeleteItem']);
    const {getProduct} = handleInventory()
    const {fetchContactProfiles} = handleContact()
    const {fetchServiceResource} = handleHospital();
    const $route = useRoute();
    const showError = inject('showError')

    const companyId = $route.params.companyId
    const additionalQuery = {
      type: "doctor"
    }
    const additionalProductQuery = {
        product_type: "services"
    }
    const serviceResourceDescriptions = ref([]);
    
    const openEditLine = (i) => {
        emit('onOpenEditItem', i)
    }

    const deleteItem = (i) => {
        emit('onDeleteItem', i)
    }

    const amount = computed(() => props.item.amount = props.item.rate * props.item.quantity)

    const discountPercent = computed(() => props.item.discount_percent);

    const amountAfterDiscount = computed(() => {
        const discountAmount = props.item.discount_amount === '' ? 0 : parseFloat(props.item.discount_amount)
        if(discountAmount === 0) {
          return amount.value;
        }
        return amount.value - discountAmount
    })

    const vatAmount = computed(() => {
        if(props.item.vat === 0) {
            return 0
        }

        if(props.item.vat) {
            return (props.item.vat/100 * amountAfterDiscount.value).toFixed(2)
        }
        return (props.item.vat/100 * amountAfterDiscount.value).toFixed(2);
    })

    const serviceResourceId = computed(() => props.item.service_resource)
    const serviceResourceDescId = computed(() => props.item.resource_description_id)

    const totalAmount = computed(() => {
        if(props.item.vat == 0) {
            return props.item.total_amount = amountAfterDiscount.value
        }
        return props.item.total_amount = parseInt(amountAfterDiscount.value) + parseInt(props.item.vat_amount)
    })

    watch(vatAmount, (newValue, oldValue) => {
        if(isNaN(oldValue)){
            return props.item.vat_amount  = props.item.vat_amount;
        }
        if(isNaN(oldValue)){
          
        }else {
          props.item.vat_amount  = newValue;
        }
    })

    watch(serviceResourceId, () => {
      if (serviceResourceId.value) {
        const companyQuery = `?company_id=${companyId}`;
        props.item.service_resource_id = serviceResourceId.value.id
        props.item.service_resource_name = serviceResourceId.value.name
        props.item.resource_description_id = null;
        props.item.service_resource_desc_name = null;
        props.item.service_resource_price = null;
        props.item.cost_of_sales_account_head_id = null;
        fetchServiceResource(serviceResourceId.value.id, companyQuery).then(res => {
          if (res.data) {
            serviceResourceDescriptions.value = res.data.resource_descriptions.map(rd => {
                return {
                  ...rd,
                  name: rd.service_name + (rd.service_specification ? ` (${rd.service_specification})` : "")
                }
            })
          }
        })
      }
    })

    watch(serviceResourceDescId, (newValue) => {
      if (newValue) {
        const description = serviceResourceDescriptions.value.find(srd => srd.id === newValue);
        props.item.service_resource_desc_name = description.name;
        props.item.service_resource_price = description.cost_of_sales_price;
        props.item.cost_of_sales_account_head_id = description.cost_of_sales_account_head_id;
      }
    })

    watch(discountPercent, (value) => {
        let discount = 0;
        value = parseFloat(value);
        if(value && value > 0 && amount.value > 0) {
          discount = amount.value * (value/100);
        }
        props.item.discount_percent = value;
        props.item.discount_amount = discount.toFixed(2);
    })

</script>
<style scoped>
.btn-dlt{
    color: #ea5455;
}
.tr-edit td{
  min-width: 80px;
}
</style>